@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lily+Script+One&display=swap");

html {
  font-family: "Inter", sans-serif;
  color: #292f36;
  background: #e3ebd2;
}

.lily {
  font-family: "Lily Script One", system-ui;
}

.big-heading {
  font-size: clamp(4.20875rem, 4.0031rem + 1.0284vw, 5.61rem);
}

.sub-heading {
  font-size: clamp(3.1575rem, 3.0032rem + 0.7716vw, 4.20875rem);
}

.sm-heading {
  font-size: clamp(2.36875rem, 2.253rem + 0.5789vw, 3.1575rem);
}

.large-text {
  font-size: clamp(1.776875rem, 1.69rem + 0.4344vw, 2.36875rem);
}

.med-text {
  font-size: clamp(1.333125rem, 1.268rem + 0.3257vw, 1.776875rem);
}

.sm-text {
  font-size: clamp(1rem, 0.9511rem + 0.2445vw, 1.333125rem);
}

.mini-text {
  font-size: clamp(0.75rem, 0.7133rem + 0.1835vw, 1rem);
}

.big-heading,
.sub-heading,
.sm-heading {
  line-height: 1;
}

.large-text,
.med-text,
.sm-text,
.mini-text {
  line-height: 1.33;
}
